<template>
  <div class="dashboard view">
    <app-header title="Dashboard" :hide-back="true"/>
    <div class="layout layout--wrap">
      <div class="flex xs12 sm12 md6">
        <div class="tile mt bodytext bodytext--larger">
          <template v-if="getLanguage() === 'de'">
            <h1><span class="text--default">Willkommen im</span> <span class="text--primary text--orbitron">Hyperium</span></h1>
            <p>Wir sind sehr glücklich darüber, euch zu unserem <span class="text--orbitron text--cyan">Dual Universe</span> Projekt einladen zu können. Wir wollen euch hier eine Vielzahl an Werkzeugen zur Verfügung stellen, die euch das Spiel leichter machen.</p>
            <p>Das Projekt ist in ständiger Weiterentwicklung. Deshalb kann es öfter zu kurzen Ausfällen kommen. Wir bitten hier um Geduld. In den meisten Fällen beträgt die Ausfallzeit weniger als eine Minute. Aus diesem Grund solltest du die Website auch mindestens ein mal am Tag neu laden, damit du immer die neusten Funktionen hast.</p>
            <p>Um euch den Einstieg etwas leichter zu machen, haben wir hier einige Hinweise für euch:</p>
            <ul class="futuristic">
              <li><span class="text--primary">Rechts oben im Menü</span> findet ihr alle euch zur Verfügung stehenden Werkzeuge.</li>
              <li class="mt">Weiterhin findet ihr rechts oben eure <span class="text--primary">Profileinstellungen</span>, wenn ihr <span class="text--primary">auf euren Namen klickt</span>. Ihr könnt euch einen <span class="text--primary">Avatar</span> konfigurieren und eure <span class="text--primary">bevorzugte Sprache</span> auswählen. Einen Upload selbst können wir aus Kosten- und Rechtsgründen leider nicht anbieten. Ihr könnt aber eine Url zu einem beliebigen Bild angeben. Wichtig ist dabei nur, dass die Url mit <span class="text--primary">https</span> anfängt. Bei Https werden die Daten zwischen Server und Client verschlüsselt übertragen und unsere Website unterstützt nur verschlüsselte Übertragungen. Verwenden könnt ihr jedes von Browsern unterstützte Bildformat <span class="text--primary">(jpg, png, svg, gif)</span>. Sollte ein Avatar allerdings Inhalte anzeigen, die wir für unangebracht halten, werden wir die Url entfernen und bei illegalen Inhalten den Account deaktivieren.</li>
              <li class="mt"><img class="img--left" src="https://cdn.hyperion-corporation.de/ui/svgs/tour.svg" height="35px"/>Haltet in den einzelnen Werkzeugen nach dem <span class="text--primary">Tour Symbol</span> Ausschau. Damit könnt ihr eine Tour durch das jeweilige Werkzeug starten, die euch alle Funktionen erklärt.</li>
              <li class="mt">Einige Werkzeuge bieten außerdem eine <span class="text--primary">Report Funktion</span>. Damit könnt ihr unvollständige oder fehlerhafte Daten melden und uns helfen die Qualität der Tools zu verbessern.</li>
              <li class="mt">Wenn ihr Fehler in den Werkzeugen findet, könnt ihr sie hier melden: <a href="https://forum.hyperion-corporation.de/category/57/bug-reports" target="_blank">Bug-Reports</a></li>
              <li class="mt">Habt ihr eine tolle Idee um ein bestehendes Werkzeug zu verbessern oder für ein neues, dann packt eure Ideen hier rein: <a href="https://forum.hyperion-corporation.de/category/58/feature-requests" target="_blank">Feature-Requests</a></li>
            </ul>
            <p>Wir hoffen ihr findet Gefallen an unserem Projekt und freuen uns auf euer Feedback.</p>
          </template>
          <template v-else>
            <h1><span class="text--default">Welcome to the</span> <span class="text--primary text--orbitron">Hyperium</span></h1>
            <p>We are very happy to invite you to our <span class="text--orbitron text--cyan">Dual Universe</span> project. We want to provide you with a variety of tools to make the game easier for you.</p>
            <p>The project is in constant development. This can often cause some short downtimes. We ask for patience here. In most cases the downtime is less than a minute. For this reason, you should also reload the website at least once a day, so that you always have the latest features.</p>
            <p>To make it a little easier for you to get started, we have some hints for you:</p>
            <ul class="futuristic">
              <li class="mt"><span class="text--primary">In the top right menu</span> you will find all the tools available to you.</li>
              <li class="mt">You can also find your <span class="text--primary">profile settings</span> by <span class="text--primary">clicking on your name</span> in the top right-hand corner. You can configure your <span class="text--primary">avatar</span> and choose your <span class="text--primary">prefered language</span>. Unfortunately, we cannot offer an upload itself for cost and legal reasons. But you can add an url to any picture. It is important that the url starts with <span class="text--primary">https</span>. With https the data is transferred encrypted between server and client and our website only supports encrypted transfers. You can use any image format supported by browsers <span class="text--primary">(jpg, png, svg, gif)</span>. However, if an avatar displays content that we consider inappropriate, we will remove the url and deactivate the account if the content is illegal.</li>
              <li class="mt"><img class="img--left" src="https://cdn.hyperion-corporation.de/ui/svgs/tour.svg" height="35px"/>Look for the <span class="text--primary">tour symbol</span> in the individual tools. With this you can start a tour through the respective tool, which explains all functions.</li>
              <li class="mt">Some tools also offer a <span class="text--primary">report function</span>. This allows you to report incomplete or incorrect data and helps us to improve the quality of the tools.</li>
              <li class="mt">If you find errors in the tools, you can report them here: <a href="https://forum.hyperion-corporation.de/category/57/bug-reports" target="_blank">Bug-Reports</a></li>
              <li class="mt">If you have a great idea to improve an existing tool or for a new one, put your ideas here: <a href="https://forum.hyperion-corporation.de/category/58/feature-requests" target="_blank">Feature-Requests</a></li>
            </ul>
            <p>We hope you enjoy our project and look forward to your feedback.</p>
          </template>
        </div>
      </div>
      <div class="flex xs12 sm12 md6">
        <progress-linear v-if="loading" :indeterminate="true"/>
        <div class="control-bar" :class="{'control-bar--disabled': loading}">
          <div class="control-bar__info">
            <div class="control-bar__info-value">News</div>
          </div>
          <text-field
              class="control-bar__search"
              v-model="searchTerm"
              :label="__('common.search_term')"
              max-length="50"
              :hide-details="true"
              :clearable="true"
              @keyup:enter="refresh(100)"
              @click:clear="refresh(100)"
          />
          <div class="control-bar__buttons">
            <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
              <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
                   alt="refresh"/>
            </div>
            <div class="control-bar__button" :title="__('common.add') + ' news'" @click="addNews" v-slashes v-if="hasOneRoles(['InGame Items Editor'])">
              <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
                   alt="add"/>
            </div>
          </div>
        </div>
        <div class="scroll-horizontal-overflow">
          <pagination
              class="mt"
              :count-pages="pagination.pages"
              :total-visible="10"
              :page="pagination.page"
              :disabled="loading"
              @paginate="paginate"
          />
          <div class="tile mt" v-for="aNews in news">
            <div class="news__created-at" v-html="renderDatetime(aNews.createdAt)"></div>
            <div class="news__title">
              {{aNews.title}}
              <span class="news__action" v-if="hasOneRoles(['InGame Items Editor'])" @click="editNews(aNews)" v-slashes>
                <icon :icon="'pencil'"/>
              </span>
              <span class="news__action" v-if="hasOneRoles(['InGame Items Editor'])" @click="deleteRecord(aNews)" v-slashes>
                <icon :icon="'bin'"/>
              </span>
            </div>
            <div class="news__bodytext" v-html="aNews.bodytext"></div>
          </div>
        </div>
      </div>
    </div>
    <edit-news ref="editNews" @created="editNews" @updated="refresh"/>
  </div>
</template>

<script>
  import AppHeader from '../components/AppHeader';
  import { notificationsMixin } from '../mixins/notifications';
  import { localizationMixin } from '../mixins/localization';
  import { pageTitleMixin } from "../mixins/pageTitle";
  import apiSecured from "../api/secured";
  import { paginationMixin } from "../mixins/pagination";
  import { userMixin } from "../mixins/user";
  import EditNews from "../components/Community/EditNews";
  import Swal from "sweetalert2";

  export default {
    mixins: [notificationsMixin, localizationMixin, pageTitleMixin, paginationMixin, userMixin],
    components: {
      EditNews,
      AppHeader
    },
    data: () => ({
      loading: false,
      news: []
    }),
    methods: {
      getLanguage() {
        return localStorage.getItem('preferredLanguage');
      },
      renderDatetime(value) {
        if (value !== null) {
          let datetime = new Date(value);
          const datestring = `${datetime.getFullYear()}-${(datetime.getMonth() + 1).toString().padStart(2, '0')}-${datetime.getDate().toString().padStart(2, '0')}`;
          const timestring = `${datetime.getHours().toString().padStart(2, '0')}:${datetime.getMinutes().toString().padStart(2, '0')}:${datetime.getSeconds().toString().padStart(2, '0')}`;
          const fDatestring = `<span class="text--teal">${datestring}</span>`;
          const fTimestring = `<span class="text--cyan">${timestring}</span>`;
          return `${fDatestring} ${fTimestring}`;
        }
        return '-';
      },
      addNews() {
        this.$refs.editNews.showCreate();
      },
      editNews(news) {
        this.$refs.editNews.showEdit(news);
      },
      async refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        params.pageSize = 5;
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/community/news', { params }).then(async (res) => {
          this.news = res.data;
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.news_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/community/news/' + record.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle('Hello there!');
      this.refresh();
    }
  }
</script>
